<template>
  <section id="modal-resource">
    <b-modal
      :id="modalId"
      centered
      size="lg"
      :title="$t('resources.preview')"
      :hide-footer="true"
    >
      <template v-if="resourceData && resourceData.item.node.type === 'DEE'">
        <b-row>
          <b-col
            lg="12"
            class="ml-2"
          >
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              label="DeepLink Android"
            >
              <div class="d-flex">
                <b-form-input
                  :value="resourceData.item.node.urlApp"
                  class="mr-1"
                  readonly
                />
                <!-- button -->
                <b-button
                  v-clipboard:copy="resourceData.item.node.urlApp"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                >
                  {{ $t("dataGeneric.copy") }}
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            lg="12"
            class="ml-2"
          >
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              label="DeepLink Android TV"
            >
              <div class="d-flex">
                <b-form-input
                  :value="resourceData.item.node.urlAppAndroidTv"
                  class="mr-1"
                  readonly
                />
                <!-- button -->
                <b-button
                  v-clipboard:copy="resourceData.item.node.urlAppAndroidTv"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                >
                  {{ $t("dataGeneric.copy") }}
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            lg="12"
            class="ml-2"
          >
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              label="Deeplink iOS"
            >
              <div class="d-flex">
                <b-form-input
                  :value="resourceData.item.node.urlAppIos"
                  class="mr-1"
                  readonly
                />
                <!-- button -->
                <b-button
                  v-clipboard:copy="resourceData.item.node.urlAppIos"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                >
                  {{ $t("dataGeneric.copy") }}
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            lg="12"
            class="ml-2"
          >
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              label="Deeplink tvOS"
            >
              <div class="d-flex">
                <b-form-input
                  :value="resourceData.item.node.urlAppIosTv"
                  class="mr-1"
                  readonly
                />
                <!-- button -->
                <b-button
                  v-clipboard:copy="resourceData.item.node.urlAppIosTv"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                >
                  {{ $t("dataGeneric.copy") }}
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col
            lg="12"
            class="ml-2"
          >
            <!-- form input -->
            <b-form-group
              class="mb-2 mr-1"
              label="DeepLink APP Web"
            >
              <div class="d-flex">
                <b-form-input
                  :value="resourceData.item.node.urlAppWeb"
                  class="mr-1"
                  readonly
                />
                <!-- button -->
                <b-button
                  v-clipboard:copy="resourceData.item.node.urlAppWeb"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onError"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="primary"
                >
                  {{ $t("dataGeneric.copy") }}
                </b-button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template v-else-if="resourceData && resourceData.item.node.type === 'A2C'">
        A2C
      </template>
      <template v-else-if="resourceData && resourceData.item.node.type === 'A2B'">
        A2B
      </template>
      <template v-else-if="resourceData && resourceData.item.node.type === 'PLN'">
        <div style="height: 500px;">
          <div
            class="w-100 h-100"
            :style="plain"
          >
            <b-row class="h-100">
              <b-col cols="4" />
              <b-col
                id="acortarPadre"
                class="p-2"
                cols="8"
              >
                <h4 id="acortar">
                  {{ resourceData.item.node.plain.title }}
                </h4>

                <span class="acortar_parrafo">
                  {{ resourceData.item.node.plain.text }}
                </span>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>
      <template v-else-if="resourceData && resourceData.item.node.type === 'TST'">
        <div style="height: 500px;">
          <div
            class="w-100 h-100"
            :style="test"
          >
            <b-row class="w-100 h-100">
              <b-col>
                <div class="mt-4 ml-4">
                  <h5 class="text-white">
                    {{ resourceData.item.node.test.intro }}
                  </h5>
                </div>
                <div class="mt-2 ml-4">
                  <h5 class="text-white font-weight-bold">
                    {{ resourceData.item.node.test.name }}
                  </h5>
                </div>
                <div class="d-flex justify-content-center mt-3">
                  <b-img
                    height="200"
                    width="200"
                    :src="resourceData.item.node.test.imageUrl"
                    fluid
                    alt="Responsive image"
                  />
                </div>
              </b-col>
              <b-col id="respuestas">
                <div class="pt-5 mt-5">
                  <h5
                    v-for="item in resourceData.item.node.test.testAnswer"
                    :key="item.response"
                    class="font-weight-bold mb-1"
                  >
                    {{ item.response }}
                  </h5>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </template>

      <template v-else-if="resourceData && resourceData.item.node.type === 'VID'">
        <div v-if="resourceData.item.node.file">
          <div
            v-if="resourceData.item.node.file.fileUrl.includes('png') ||
              resourceData.item.node.file.fileUrl.includes('jpg') ||
              resourceData.item.node.file.fileUrl.includes('jpeg')
            "
            class="d-flex justify-content-center mt-3"
          >
            <b-img
              :src="resourceData.item.node.file.fileUrl"
              fluid
              alt="Responsive image"
            />
          </div>
          <div
            v-if="resourceData.item.node.file.fileUrl.includes('mp3') ||
              resourceData.item.node.file.fileUrl.includes('mp4')"
            class="embed-responsive embed-responsive-16by9"
          >
            <video
              id="demo"
              controls
              allowfullscreen="allowfullscreen"
              class="
                video-js
                vjs-default-skin vjs-big-play-centered
                embed-responsive-item"
              data-setup="{&quot;liveui&quot;: true}"
              :poster="resourceData.item.node.file ?
                resourceData.item.node.file.fileUrl.includes('mp3')
                  ? require('@/assets/images/icons/audio.svg') : ''
                : resourceData.item.node.directUrl.includes('mp3')
                  ? require('@/assets/images/icons/audio.svg')
                  : ''"
              @loadeddata="videoMaker()"
              @loadstart="videoMaker()"
            >
              <source
                v-if="resourceData.item.node.file"
                :src="resourceData.item.node.file.fileUrl"
                :type="resourceData.item.node.file
                  ? resourceData.item.node.file.fileUrl.includes('mp4')
                    ? 'video/mp4'
                    : resourceData.item.node.file.fileUrl.includes('mp3')
                      ? 'audio/mpeg'
                      : 'application/x-mpegURL'
                  : ''
                "
              >
            </video>
          </div>
        </div>

        <div v-else>
          <div class="embed-responsive embed-responsive-16by9">
            <video
              id="demo"
              controls
              allowfullscreen="allowfullscreen"
              class="
                video-js
                vjs-default-skin vjs-big-play-centered
                embed-responsive-item"
              data-setup="{&quot;liveui&quot;: true}"
              @loadeddata="videoMaker()"
              @loadstart="videoMaker()"
            >
              <source
                :src="resourceData.item.node.directUrl"
                :type="resourceData.item.node.directUrl.includes('m3u8')
                  ? 'application/x-mpegURL'
                  : resourceData.item.node.directUrl.includes('mp4')
                    ? 'video/mp4'
                    : 'audio/mp3'
                "
              >
            </video>
          </div>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import videojs from 'video.js'
import 'videojs-markers/dist/videojs.markers.css'
import 'videojs-markers/dist/videojs.markers.min.css'
import 'video.js/dist/video-js.css'
import 'video.js/dist/video.min'
import 'video.js/dist/video'
import 'videojs-markers/dist/videojs-markers'
import 'videojs-markers/dist/videojs-markers.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming.min'
// eslint-disable-next-line import/no-extraneous-dependencies
import '@videojs/http-streaming/dist/videojs-http-streaming'
import { showToast } from '@/store/functions'

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BFormGroup,
    BFormInput,
    BButton,
    BImg,
  },
  directives: {
    Ripple,
  },

  props: {
    modalId: String,
    resourceData: {
      type: Object,
    },
  },
  data() {
    return {
      test: '',
      plain: '',
      video: null,
    }
  },
  watch: {
    resourceData() {
      if (this.video) {
        if (this.resourceData.item.node.file) {
          if (this.resourceData.item.node.file.fileUrl.includes('m3u8')) {
            this.video.src({
              type: 'application/x-mpegURL',
              src: this.resourceData.item.node.file.fileUrl,
            })
          }
          if (this.resourceData.item.node.file.fileUrl.includes('mp4')) {
            this.video.src({
              type: 'video/mp4',
              src: this.resourceData.item.node.file.fileUrl,
            })
          }
          if (this.resourceData.item.node.file.fileUrl.includes('mp3')) {
            this.video.src({
              type: 'audio/mp3',
              src: this.resourceData.item.node.file.fileUrl,
            })
          }
        } else {
          if (this.resourceData.item.node.directUrl.includes('m3u8')) {
            this.video.src({
              type: 'application/x-mpegURL',
              src: this.resourceData.item.node.directUrl,
            })
          }

          if (this.resourceData.item.node.directUrl.includes('mp4')) {
            this.video.src({
              type: 'video/mp4',
              src: this.resourceData.item.node.directUrl,
            })
          }
          if (this.resourceData.item.node.directUrl.includes('mp3')) {
            this.video.src({
              type: 'audio/mp3',
              src: this.resourceData.item.node.directUrl,
            })
          }
        }
      }
    },
  },
  updated() {
    if (this.resourceData.item.node.type === 'TST') {
      this.test = `background-image: linear-gradient(to left, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url(${this.resourceData.item.node.test.backgroundUrl}); background-size: cover;`
    } else if (this.resourceData.item.node.type === 'PLN') {
      this.plain = `background-image: linear-gradient(to left, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0)), url(${this.resourceData.item.node.plain.imageUrl}); background-size: cover;`
    }
  },
  beforeDestroy() {
    if (this.video) this.video.dispose()
  },
  methods: {
    onCopy() {
      showToast(this.$t('code.tCopy'), 1, this)
    },
    onError() {
      showToast(this.$t('code.errorCopy'), 2, this)
    },
    videoMaker() {
      this.video = videojs('demo')
      this.video.on('ready', function () {
        try {
          this.video.vhs = null
          // eslint-disable-next-line no-empty
        } catch (error) { }
      })

      this.video.on('timeupdate', () => { })
    },
  },
}
</script>

<style lang="scss" scoped>
#respuestas {
  display: flex;
  align-content: center;
  align-items: center;
}

#modal-resource #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

.acortar_parrafo {
  display: block;
  display: -webkit-box;
  /* height: 559px; */
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 22;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wrap {
  white-space: pre-wrap;
}

#acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
