/* eslint-disable*/

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useResourcesList() {
    // Use toast
    const toast = useToast()

    const refResourceListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        { key: 'name', sortable: true, label: "firstName" },
        { key: 'description', sortable: true, label: "dataGeneric.description" },
        { key: 'type', sortable: true, label: "code.type" },
        { key: 'modifiedAt', label: 'modifiedAt' },
        { key: 'actions', label: "resources.tableHeader.actions" },
    ]
    const perPage = ref(20)
    const totalResources = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const searchType = ref('null')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)

    const dataMeta = computed(() => {
        const localItemsCount = refResourceListTable.value ? refResourceListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalResources.value,
        }
    })

    const refetchData = () => {
        refResourceListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, searchType, statusFilter], () => {
        refetchData()
    })

    const fetchResources = (ctx, callback) => {
        store
            .dispatch('contents-resources/fetchResources', {
                q: searchQuery.value,
                t: searchType.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
            })
            .then(response => {
                const { edges, totalCount } = response.data.data.allResources
                callback(edges)
                totalResources.value = totalCount
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: i18n.t('errorListRes'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    const deleteResource = (id, type, test,file) => {
        store
            .dispatch('contents-resources/deleteResource', {
                id,
                type,
                test,
                file,
            })
            .then(response => {
                const { found, deletedId } = response
                if (found && deletedId) {
                    refetchData()
                    toast({
                        component: ToastificationContent,
                        props: {
                            icon: 'success',
                            title: i18n.t('deleteSuccess'),
                            text: i18n.t('deleteRes'),
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        },
                    })
                }
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: i18n.t('error'),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    return {
        fetchResources,
        deleteResource,
        tableColumns,
        perPage,
        currentPage,
        totalResources,
        dataMeta,
        perPageOptions,
        searchQuery,
        searchType,
        sortBy,
        isSortDirDesc,
        refResourceListTable,

        refetchData,

        // Extra Filters
        statusFilter,
    }
}