<template>
  <section id="resources">
    <b-row class="mb-5">
      <b-col
        md="9"
        class="pb-3 pb-md-0"
      >
        <h1>{{ $t("Resources") }}</h1>
        <h5 class="text-primary">
          {{ $t("resources.resourceSubtitle") }}
        </h5>
      </b-col>
      <b-col
        md="3"
        class="text-right"
        align-self="end"
      >
        <b-button
          variant="primary"
          @click="showTypeCards = !showTypeCards"
        >
          <feather-icon
            icon="FolderPlusIcon"
            class="mr-50"
          />
          <span class="text-nowrap">{{ $t("resources.addResource") }}</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row v-if="showTypeCards">
      <b-col>
        <router-link :to="{ name: 'resources-create', params: { type: 'MEDIA' } }">
          <b-card
            :img-src="require('@/assets/images/iCONOS_O2/iconos_O2-07.png')"
            img-height="150"
          >
            {{ $t("Media") }}
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link :to="{ name: 'resources-create', params: { type: 'TEST' } }">
          <b-card
            :img-src="require('@/assets/images/iCONOS_O2/iconos_O2-08.png')"
            img-height="150"
          >
            {{ $t("resources.questionnaire") }}
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link :to="{ name: 'resources-create', params: { type: 'INFO' } }">
          <b-card
            :img-src="require('@/assets/images/iCONOS_O2/iconos_O2-09.png')"
            img-height="150"
          >
            {{ $t("resources.moreInfo") }}
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link :to="{ name: 'resources-create', params: { type: 'BUY' } }">
          <b-card
            :img-src="require('@/assets/images/iCONOS_O2/iconos_O2-10.png')"
            img-height="150"
          >
            {{ $t("resources.buy") }}
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link :to="{ name: 'resources-create', params: { type: 'CART' } }">
          <b-card
            :img-src="require('@/assets/images/iCONOS_O2/iconos_O2-11.png')"
            img-height="150"
          >
            {{ $t("resources.sendChart") }}
          </b-card>
        </router-link>
      </b-col>
      <b-col>
        <router-link :to="{ name: 'resources-create', params: { type: 'DLINK' } }">
          <b-card
            :img-src="require('@/assets/images/iCONOS_O2/iconos_O2-12.png')"
            img-height="150"
          >
            DeepLink
          </b-card>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <b-row class="m-1">
            <!-- Search -->
            <b-col>
              <div class="d-flex align-items-center justify-content-end">
                <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  :placeholder="$t('Search')"
                />
              </div>
            </b-col>
            <!-- Filter -->
            <b-col>
              <b-form-select
                v-model="searchType"
                :options="resourceType"
              />
            </b-col>
          </b-row>
          <b-table
            ref="refResourceListTable"
            :items="fetchResources"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            show-empty
            :empty-text="$t('emptyText')"
            responsive
            fixed
            primary-key="id"
          >
            <template #head(name)="data">
              <span>{{ $t(data.label) }}</span>
            </template>
            <template #cell(name)="data">
              <p>{{ data.item.node.name }}</p>
            </template>
            <template #head(description)="data">
              <span>{{ $t(data.label) }}</span>
            </template>
            <template #cell(description)="data">
              <div id="acortarPadre">
                <div class="wrap">
                  <span class="acortarParrafo">
                    {{ data.item.node.description }}
                  </span>
                </div>
              </div>
            </template>
            <template #head(type)="data">
              <span>{{ $t(data.label) }}</span>
            </template>
            <template #cell(type)="data">
              {{ resourceType[data.item.node.type] }}
            </template>
            <template #head(modifiedAt)="data">
              <span>{{ $t(data.label) }}</span>
            </template>
            <template #cell(modifiedAt)="data">
              {{ fechaFormato(data.item.node.modifiedAt) }}
            </template>
            <template #head(actions)="data">
              <span>{{ $t(data.label) }}</span>
            </template>
            <template #cell(actions)="data">
              <div class="float-right">
                <feather-icon
                  v-if="data.item.node.type === 'DEE' ||
                    data.item.node.type === 'VID' ||
                    data.item.node.type === 'TST' ||
                    data.item.node.type === 'PLN'
                  "
                  icon="EyeIcon"
                  size="17"
                  class="text-info cursor-pointer"
                  @click="resourceSelected(data)"
                />
                <b-link
                  v-if="data.item.node.type === 'TST'"
                  class="ml-1"
                  :to="{
                    name: 'resources-test-detail',
                    params: { id: data.item.node.test.id }
                  }"
                >
                  <feather-icon
                    icon="ClipboardIcon"
                    size="17"
                    class="text-info"
                  />
                </b-link>
                <b-link
                  class="ml-1"
                  :to="{
                    name: 'resources-media-edit',
                    params: { id: data.item.node.id }
                  }"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="17"
                    class="text-warning"
                  />
                </b-link>
                <b-link
                  v-if="data.item.node.type === 'TST'"
                  class="ml-1"
                  @click="
                    toastDelete(
                      data.item.node.id,
                      data.item.node.type,
                      data.item.node.test.testAnswer
                    )
                  "
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="17"
                    class="text-danger"
                  />
                </b-link>
                <b-link
                  v-else
                  class="ml-1"
                  @click="toastDelete(data.item.node.id, data.item.node.type)"
                >
                  <feather-icon
                    icon="XCircleIcon"
                    size="17"
                    class="text-danger"
                  />
                </b-link>
              </div>
            </template>
          </b-table>
          <table-footer
            v-model="currentPage"
            :from="dataMeta.from"
            :to="dataMeta.to"
            :total="totalResources"
            :per-page="perPage"
          />
        </b-card>
      </b-col>
    </b-row>
    <modal-resource-preview
      modal-id="modal-resource-preview"
      :resource-data="resourceSelectedData"
    />
    <b-modal
      ref="modal"
      size="lg"
      hide-footer
      :title="$t('realRes')"
    >
      <div class="d-block text-center">
        <b-row class="mb-2">
          <b-col>
            <h4>
              {{ $t('listResource') }} {{ nameResource }}
            </h4>
          </b-col>
        </b-row>
        <b-row class="mb-2 ">
          <b-col
            class="text-center bg-warning bg-darken-2 colors-container rounded text-white width-100 height-100 d-flex align-items-center justify-content-center mr-1 ml-50 my-1 shadow"
          >
            <div>
              <feather-icon
                icon="AlertTriangleIcon"
                size="30"
              />
              <span>
                {{ $t('desvRes') }}
              </span>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-row v-if="initialRes">
              <b-col>
                <strong>{{ $t('Contents') }}</strong>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-list-group
                  v-for="cont in contentResource.edges"
                  :key="cont.node.id"
                  tag="ul"
                >
                  <b-list-group-item
                    v-for="init in cont.node.contentResources.edges"
                    :key="init.node.id"
                    tag="li"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>
                      {{ init.node.content }}
                    </span>
                    <feather-icon
                      icon="EditIcon"
                      size="18"
                      class="mr-50 text-success cursor-pointer"
                      @click="editResources(0, cont.node)"
                    />
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col>
            <b-row v-if="interactivitieResource.totalCount != 0">
              <b-col>
                <strong>{{ $t('resFunciones.isInteractivity.title') }}</strong>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-list-group tag="ul">
                  <b-list-group-item
                    v-for="int in interactivitieResource.edges"
                    :key="int.node.id"
                    tag="li"
                    class="d-flex justify-content-between align-items-center"
                  >
                    <span>
                      {{ int.node.name }}
                    </span>
                    <feather-icon
                      icon="EditIcon"
                      size="18"
                      variant="danger"
                      class="mr-50 text-success cursor-pointer"
                      @click="editResources(1, int.node)"
                    />
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['modal'].hide()"
      >
        {{
          $t("dataGeneric.cancel")
        }}
      </b-button>
    </b-modal>
    <b-modal
      ref="resource-modal"
      size="xl"
      hide-footer
      :title="$t('editContent.recSelect')"
    >
      <div class="d-block text-center">
        <resources-selector
          :msg="type"
          :contenido="tipo == 0 ? idCont : null"
          :recurso="contentResourceSelected"
          @data="seleccionarRecurso"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['resource-modal'].hide()"
      >
        {{
          $t("dataGeneric.close")
        }}
      </b-button>
    </b-modal>
  </section>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import {
  BListGroup,
  BListGroupItem,
  BTable,
  BRow,
  BCol,
  BCard,
  BModal,
  BLink,
  BButton,
  BFormSelect,
  BFormInput,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import axios from '@axios'
import { messageError, utf8ToB64 } from '@/store/functions'
import resourcesStoreModule from './resourcesStoreModule'
import useResourcesList from './useResourcesList'
import ModalResourcePreview from './ModalResourcePreview.vue'
import TableFooter from '../../components/table/TableFooter.vue'

import ResourcesSelector from '../../common/ResourcesSelector.vue'

export default {
  components: {
    BTable,
    BRow,
    BFormInput,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    ResourcesSelector,
    BLink,
    BButton,
    BFormSelect,
    BModal,
    // My components
    TableFooter,
    ModalResourcePreview,
  },
  emits: ['update:perPage', 'update:searchQuery', 'update:searchType'],
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'contents-resources'

    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, resourcesStoreModule)

    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewResourceSidebarActive = ref(false)

    const {
      fetchResources,
      deleteResource,
      tableColumns,
      perPage,
      currentPage,
      totalResources,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchType,
      sortBy,
      isSortDirDesc,
      refResourceListTable,
      refetchData,

      // Extra Filters
      statusFilter,
    } = useResourcesList()

    const showTypeCards = false
    return {
      isAddNewResourceSidebarActive,
      fetchResources,
      deleteResource,
      tableColumns,
      perPage,
      currentPage,
      totalResources,
      dataMeta,
      perPageOptions,
      searchQuery,
      searchType,
      sortBy,
      isSortDirDesc,
      refResourceListTable,
      refetchData,
      showTypeCards,
      // Extra Filters
      statusFilter,
      avatarText,
    }
  },
  data() {
    return {
      type: 'VID',
      destRes: null,
      idCont: null,
      idfile: null,
      contentResourceSelected: null,
      interactivitieResource: [],
      contentResourcesListForUpdate: [],
      nameResource: null,
      contentResource: [],
      initialRes: false,
      tipo: 0,
      resourceType: {
        null: this.$t('resources.notFilter'),
        VID: this.$t('resources.media'),
        TST: this.$t('resources.Survey'),
        PLN: this.$t('resources.img'),
        A2B: this.$t('Purchases'),
        A2C: this.$t('resources.add2Cart'),
        BUY: `${this.$t('Purchases')} ${this.$t('dataGeneric.internal')}`,
        CAR: `${this.$t('resources.add2Cart')} ${this.$t('dataGeneric.internal')}`,
        DEE: this.$t('resources.deepLink'),
      },
      internalPerPage: 10,
      internalSearchQuery: '',
      // perPageOptions: [10, 25, 50, 100],
      resourceSelectedData: null,
    }
  },
  watch: {
    internalPerPage(newValue) {
      this.$emit('update:perPage', newValue)
    },
    internalSearchQuery(newValue) {
      this.$emit('update:searchQuery', newValue)
    },
    internalsearchType(newValue) {
      this.$emit('update:searchType', newValue)
    },
    perPage(newValue) {
      this.internalPerPage = newValue
    },
    searchQuery(newValue) {
      this.internalSearchQuery = newValue
    },
    searchType(newValue) {
      this.internalsearchType = newValue
    },
  },
  methods: {
    fechaFormato(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },
    resourceSelected(data) {
      axios
        .post('', {
          query: `
        {
        allResources(id:"${data.item.node.id}") {
              edges {
                    node {
                      id
                      type
                      urlApp
                      urlAppAndroidTv
                      urlAppIos
                      urlAppIosTv
                      urlAppWeb
                      modifiedAt
                      directUrl
                      file {
                        id
                        name
                        fileUrl
                      }
                      test {
                        id
                        name
                        intro
                        imageUrl
                        backgroundUrl
                        testAnswer{
                          id
                          response
                          order
                        }
                      }
                      plain {
                        title
                        text
                        imageUrl
                      }
                    }
                  }
                }
              }
        `,
        })
        .then(result => {
          messageError(result, this)
          this.resourceSelectedData = {
            item: result.data.data.allResources.edges[0],
          }

          this.$bvModal.show('modal-resource-preview')
        })
        .catch(() => { })
    },
    confirmText(id, type, test, file) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('code.text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteResource(id, type, test, file)
        }
      })
    },
    toastDelete(idRes = null, typeRes = null, testRes = null) {
      const id = idRes == null ? this.idDel : idRes
      const type = typeRes == null ? this.typeDel : typeRes
      const test = testRes == null ? this.testDel : testRes
      this.contentResourceSelected = id
      axios
        .post('', {
          query: `
           {
            allResources(id:"${id}") {
              edges {
                node {
                  id
                  name
                  modifiedAt
                  typeStream
                  file {
                    id
                    fileResource {
                      totalCount
                      edges {
                        node {
                          id
                          name
                        }
                      }
                    }
                  }
                  resourceInteractivity {
                    totalCount
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  resource2Interactivity {
                    totalCount
                    edges {
                      node {
                        id
                        name
                         resourceStart {
                          id
                          name
                          type

                        }
                        resourceEnd {
                          id
                          name
                          type

                        }
                      }
                    }
                  }
                  resourceContent {
                    totalCount
                    edges {
                      node {
                        id
                        name
                        contentResources(resource:"${utf8ToB64(
    `id:${id}`,
  )}",isInitial:true) {
                        totalCount
                        edges {
                          node {
                            id
                            isInitial
                            content
                             resource {
                              id
                            }
                          }
                        }
                      }
                      }
                    }
                  }
                }
              }
            }
          }
            `,
        })
        .then(result => {
          messageError(result, this)
          const response = result.data.data.allResources.edges[0].node
          let { file } = response
          if (file) {
            file = file.fileResource.totalCount === 1 ? file.id : null
          }
          this.nameResource = response.name
          this.interactivitieResource = response.resource2Interactivity
          this.contentResource = response.resourceContent
          this.initialRes = false

          this.contentResource.edges.forEach(element => {
            if (element.node.contentResources.totalCount !== 0) this.initialRes = true
          })

          const hash = {}

          this.contentResource.edges = this.contentResource.edges.filter(o => {
            if (hash[o.node.id]) {
              return false
            }
            hash[o.node.id] = true
            return true
          })
          if (!this.initialRes && this.interactivitieResource.totalCount === 0) {
            this.$refs.modal.hide()

            this.confirmText(
              id == null ? this.idDel : id,
              type == null ? this.typeDel : type,
              test == null ? this.testDel : test,
              file,
            )
          } else {
            const { typeStream } = response
            if (typeStream === 'AWS' || typeStream === 'WOW') {
              // Muestra un mensaje de error si es "AWS" o "WOW"
              this.$swal({
                title: this.$t('notRemRes'),
                text: this.$t('remResExternal'),
                icon: 'error',
                showCancelButton: false,
                confirmButtonText: 'OK',
                customClass: {
                  confirmButton: 'btn btn-primary',
                  cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
              })
            } else {
              this.idDel = id
              this.typeDel = type
              this.testDel = test
              this.$refs.modal.show()
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    editResources(type, data) {
      this.tipo = type
      this.idCont = data.id
      if (type === 0) {
        this.contentResourcesListForUpdate = data.contentResources.edges.length
          ? data.contentResources.edges.map(item => item.node.resource.id)
          : []
      } else {
        try {
          this.resInfo = data.resourceStart.id === this.contentResourceSelected
            ? data.resourceStart
            : data.resourceEnd

          this.destRes = data.resourceStart.id === this.contentResourceSelected
            ? 'START'
            : 'END'

          this.type = this.resInfo.type
        } catch (error) {
          console.log(error)
        }
      }
      this.$refs['resource-modal'].show()
    },
    seleccionarRecurso(data) {
      this.$refs['resource-modal'].hide()
      const [res] = data
      this.contentResourceSelected = res

      if (this.tipo === 0) { this.updateResourceInitial() } else this.updateResourceInt()
    },
    updateResourceInt() {
      const id = this.contentResourceSelected

      const query = `
         mutation{
          updateInteractivities(id:"${this.idCont}",input:{${this.destRes !== 'START'
  ? `resourceEnd:"${id}"`
  : `resourceStart:"${id}"`
}}){
            interactivity{
              id
              name
            }
          }
        }
      `
      axios
        .post('', {
          query,
        })
        .then(result => {
          messageError(result, this)

          this.toastDelete()
        })
        .catch(() => { })
    },
    updateResourceInitial() {
      const id = this.idCont

      let query = `
       {
        allContents(id:"${id}") {
          edges {
            node {
              id
              name
              resources {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
              contentResources {
                edges {
                  node {
                    id
                    isInitial
                    content
                    resource {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
        }
      `

      axios
        .post('', {
          query,
        })
        .then(res => {
          messageError(res, this)

          const contentResources = res.data.data.allContents.edges[0].node.contentResources.edges

          if (contentResources.length === 0) {
            query = 'mutation{'
            query += `inicial: createContentsResource(input:{content:"${id}",resource:"${this.contentResourceSelected}",isInitial:true}) {
                 contentResource2{
                id
              isInitial
                content
                resource{
                  id
                  name
                  file{
                    id
                    mediaDuration
                  }
                }
              }
              }
              `

            query += '}'

            axios
              .post('', {
                query,
              })
              .then(result => {
                messageError(result, this)

                try {
                  const duration = result.data.data.inicial.contentResource2.resource.file
                    .mediaDuration

                  axios
                    .post('', {
                      query: `mutation{
                        updateContents(id:"${id}",input:{duration:${duration || 0}}){
                          content{
                            id
                            name
                            duration
                          }
                        }
                      }`,
                    })
                    .then(r => {
                      messageError(r, this)
                      this.toastDelete()
                    })
                    .catch(err => {
                      console.log(err)
                    })
                } catch (error) {
                  console.log(error)
                }
              })
              .catch(err => {
                console.log(err)
              })
          } else {
            let query2 = 'mutation{'
            contentResources.forEach((element, i) => {
              query2 += `a${i}:deleteContentsResource(id:"${element.node.id}"){
              found
                deletedId
              }`
            })
            query2 += '}'

            axios
              .post('', {
                query: query2,
              })
              .then(result => {
                messageError(result, this)

                query2 = 'mutation{'
                query2 += `inicial: createContentsResource(input:{content:"${id}",resource:"${this.contentResourceSelected}",isInitial:true}) {
                 contentResource2{
                id
              isInitial
                content
                resource{
                  id
                  name
                  file{
                    id
                    mediaDuration
                  }
                }
              }
              }
              `

                this.contentResourcesListForUpdate.forEach((element, i) => {
                  if (this.contentResourceSelected !== element) {
                    query2 += `a${i}: createContentsResource(input:{content:"${id}",resource:"${element}",isInitial:false}){
                  contentResource2{
                    id
                  isInitial
                    content
                    resource {
                      id
                      name
                      file{
                          id
                          mediaDuration
                        }
                    }
                    }
                  }`
                  }
                })
                query2 += '}'
                axios
                  .post('', {
                    query: query2,
                  })
                  .then(r => {
                    messageError(r, this)

                    try {
                      const duration = r.data.data.inicial.contentResource2.resource
                        .file != null
                        ? r.data.data.inicial.contentResource2.resource
                          .file.mediaDuration
                        : 0
                      axios
                        .post('', {
                          query: `mutation($id : ID!, $duration: Int){
                        updateContents(id:$id,input:{duration:$duration}){
                          content{
                            id
                            name
                            duration
                          }
                        }
                      }`,
                          variables: {
                            id,
                            duration: duration || 0,
                          },
                        })
                        .then(response => {
                          messageError(response, this)
                          this.toastDelete()
                        })
                        .catch(err => {
                          console.log(err)
                        })
                    } catch (error) {
                      console.log(error)
                    }
                  })
                  .catch(err => {
                    console.log(err)
                  })
              })
              .catch(err => {
                console.log(err)
              })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    limpiarNombre(nombre) {
      let name = nombre
      name = name.split(' ')

      name.shift()

      name = name.join(' ')

      return name
    },
  },
}
</script>
<style scoped>
#resources .card-img {
  background-color: white;
}

#resources .card {
  color: white;
}

#resources #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#resources #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#resources .acortarParrafo {
  display: block;
  display: -webkit-box;
  /* height: 559px; */
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

#resources .wrap {
  white-space: pre-wrap;
}
</style>
